import {ADM, ADM_FORMAT, ALL} from '../../../constants';

export const getInitialForm = (protocol, adType) => {
  if (protocol === 'oRTB') {
    switch (adType) {
      case 'BANNER':
      case 'NATIVE':
      case 'PUSH':
      case 'POP': {
        return ({
          isAdvanced: false,
          integrationType: 'RTB',
          isGateway: true,
          location: 'Usa',
          protocolType: 'oRTB',
          adType: adType,
          trafficType: ALL,
          deviceType: [],
          qps: null,
          auctionType: ALL,
          tmax: 100,
          defaultBidfloor: null,
          paymentsWith: ADM,
          isHtmlFormat: true,
          impressionTtl: 20,
          isAllowImpDuplicates: false,
          isFilterMaskedIP: false,
          isLookupUserId: false,
          bidParams: [],
          maxErrorRate: null,
          maxNoMatchRate: null,
          clickTtl: 48,
          isAllowMismatch: false,
          isAllowClickMismatch: false,
          isAllowClickDuplicates: false,
          isClickWithoutImpRule2: false,
          isSkipCreativeSize: false,
          isValidationDefault: false,
        });
      }

      case 'VIDEO': {
        return ({
          isAdvanced: false,
          integrationType: 'RTB',
          isGateway: true,
          location: 'Usa',
          protocolType: 'oRTB',
          adType: adType,
          trafficType: ALL,
          deviceType: [],
          qps: null,
          auctionType: ALL,
          tmax: 100,
          defaultBidfloor: null,
          paymentsWith: ADM,
          isHtmlFormat: true,
          impressionTtl: 20,
          isAllowImpDuplicates: false,
          isFilterMaskedIP: false,
          isLookupUserId: false,
          bidParams: [],
          maxErrorRate: null,
          maxNoMatchRate: null,
          clickTtl: 48,
          isAllowMismatch: false,
          isAllowClickMismatch: false,
          isAllowClickDuplicates: false,
          isClickWithoutImpRule2: false,
          isSkipCreativeSize: true,
          isValidationDefault: false,
          admFormat: ADM_FORMAT.vast_3_0,
        });
      }
    }
  } else if (protocol === 'XML') {
    switch (adType) {
      case 'POP':
      case 'CALENDAR': {
        return ({
          isAdvanced: false,
          integrationType: 'RTB',
          isGateway: true,
          location: 'Usa',
          protocolType: 'XML',
          adType: adType,
          deviceType: [],
          qps: null,
          tmax: 100,
          isAllowMismatch: false,
          isAllowClickMismatch: false,
          impressionTtl: 20,
          isAllowImpDuplicates: false,
          isFilterMaskedIP: false,
          isLookupUserId: false,
          bidParams: [],
          maxErrorRate: null,
          maxNoMatchRate: null,
          isSkipCreativeSize: false,
          isValidationDefault: false,
        });
      }

      case 'PUSH': {
        return ({
          isAdvanced: false,
          integrationType: 'RTB',
          isGateway: true,
          location: 'Usa',
          protocolType: 'XML',
          adType: adType,
          deviceType: [],
          qps: null,
          tmax: 100,
          impressionTtl: 20,
          isAllowMismatch: false,
          isAllowImpDuplicates: false,
          isFilterMaskedIP: false,
          isLookupUserId: false,
          bidParams: [],
          maxErrorRate: null,
          maxNoMatchRate: null,
          clickTtl: 48,
          isAllowClickMismatch: true,
          allowClickMismatch: ['isAllowClickIp'],
          isAllowClickDuplicates: false,
          isClickWithoutImpRule2: false,
          isSkipCreativeSize: false,
          isValidationDefault: false,
        });
      }
    }
  }
};

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import React from 'react';
import {Checkboxes} from 'mui-rff';
import {stringToBoolean} from 'utils/common';
import {ADM_FORMAT} from '../../../../constants';

export const RadioAdapter = ({input, ...rest}) => {
  return (
    <FormControl disabled>
      <FormLabel id='integrationType'>Integration type</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='integrationType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='RTB' control={<Radio />} label='RTB' />
        <FormControlLabel value='DIRECT' control={<Radio />} label='DIRECT' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter2 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='adType'>Endpoint ad type</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='adType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='BANNER' control={<Radio />} label='BANNER' />
        <FormControlLabel value='NATIVE' control={<Radio />} label='NATIVE' />
        <FormControlLabel value='VIDEO' control={<Radio />} label='VIDEO' />
        <FormControlLabel value='AUDIO' control={<Radio />} label='AUDIO' />
        <FormControlLabel value='PUSH' control={<Radio />} label='PUSH' />
        <FormControlLabel value='POP' control={<Radio />} label='POP' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter3 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='protocol'>Protocol</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='protocol'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='oRTB' control={<Radio />} label='oRTB' />
        <FormControlLabel value='XML' control={<Radio />} label='XML/JSON' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter4 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isAdvanced'>Advanced options</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='isAdvanced'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='HIDE' />
        <FormControlLabel value control={<Radio />} label='SHOW' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter5 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='adType'>Endpoint ad type</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='adType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='PUSH' control={<Radio />} label='PUSH' />
        <FormControlLabel value='POP' control={<Radio />} label='POP' />
        <FormControlLabel value='CALENDAR' control={<Radio />} label='CALENDAR' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter6 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='trafficType'>Inventory type</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='trafficType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='ALL' control={<Radio />} label='ALL' />
        <FormControlLabel value='WEB' control={<Radio />} label='WEB' />
        <FormControlLabel value='IN APP' control={<Radio />} label='IN APP' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter7 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='auctionType'>Auction type</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='auctionType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='ALL' control={<Radio />} label='ALL' />
        <FormControlLabel value='FIRST PRICE' control={<Radio />} label='FIRST PRICE' />
        <FormControlLabel value='SECOND PRICE' control={<Radio />} label='SECOND PRICE' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter8 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='paymentsWith'>Calculate payout with</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='paymentsWith'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='adm' control={<Radio />} label='ADM' />
        <FormControlLabel value='nurl' control={<Radio />} label='NURL' />
        <FormControlLabel value='burl' control={<Radio />} label='BURL' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter9 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isHtmlFormat'>ADM format</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isHtmlFormat'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value control={<Radio />} label='HTML' />
        <FormControlLabel value={false} control={<Radio />} label='XML' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter10 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='adType'>Endpoint ad type</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='adType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='BANNER' control={<Radio />} label='BANNER' />
        <FormControlLabel value='POP' control={<Radio />} label='POP' />
        <FormControlLabel value='HTML5' control={<Radio />} label='HTML5' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter11 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='formatAdType'>Format</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='formatAdType'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value='HTML_TAG' control={<Radio />} label='HTML_TAG' />
        <FormControlLabel value='DIRECT_LINK' control={<Radio />} label='DIRECT_LINK' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter12 = ({input, ...rest}) => {
  return (
    <FormControl>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isGateway'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Manual Integration' />
        <FormControlLabel value control={<Radio />} label='Gateway' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter13 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isValidationDefault'>Validation</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isValidationDefault'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value control={<Radio />} label='Default' />
        <FormControlLabel value={false} control={<Radio />} label='Custom' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter14 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isAllowMismatch'>Allow impression mismatch parameters</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isAllowMismatch'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Off' />
        <FormControlLabel value control={<Radio />} label='On' />
      </RadioGroup>
      {
        stringToBoolean(input.value) ?
          <Checkboxes
            name='allowMismatch'
            data={[
              {label: 'IP', value: 'isAllowIp'},
              {label: 'Geo', value: 'isAllowGeo'},
              {label: 'Device', value: 'isAllowDevice'},
              {label: 'OS', value: 'isAllowOs'},
            ]}
          /> : null
      }
    </FormControl>
  );
};

export const RadioAdapter15 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isAllowImpDuplicates'>Allow impressions duplicates</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isAllowImpDuplicates'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter16 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isFilterMaskedIP'>Filter masked IPS</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isFilterMaskedIP'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter17 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isLookupUserId'>Lookup user by user.id</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isLookupUserId'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter18 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isAllowImpDuplicates'>Skip creative size matching</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isSkipCreativeSize'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter19 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isAllowClickMismatch'>Allow click mismatch parameters</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isAllowClickMismatch'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Off' />
        <FormControlLabel value control={<Radio />} label='On' />
      </RadioGroup>
      {
        stringToBoolean(input.value) ?
          <Checkboxes
            name='allowClickMismatch'
            data={[
              {label: 'IP', value: 'isAllowClickIp'},
              {label: 'Geo', value: 'isAllowClickGeo'},
              {label: 'Device', value: 'isAllowClickDevice'},
              {label: 'OS', value: 'isAllowClickOs'},
            ]}
          /> : null
      }
    </FormControl>
  );
};

export const RadioAdapter20 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isAllowClickDuplicates'>Allow clicks duplicates</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isAllowClickDuplicates'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter21 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isClickWithoutImpRule2'>Clicks without impressions</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isClickWithoutImpRule2'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter22 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='admFormat'>ADM format</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='admFormat'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={ADM_FORMAT.vast_3_0} control={<Radio />} label='VAST 3.0' />
        <FormControlLabel value={ADM_FORMAT.json} control={<Radio />} label='JSON' />
      </RadioGroup>
    </FormControl>
  );
};

export const RadioAdapter23 = ({input, ...rest}) => {
  return (
    <FormControl>
      <FormLabel id='isCapPubUID'>Cap per Publisher User ID</FormLabel>
      <RadioGroup
        {...rest}
        row
        aria-labelledby='row-radio-buttons-group-label'
        name='isCapPubUID'
        value={input.value}
        onChange={(event, value) => input.onChange(value)}
      >
        <FormControlLabel value={false} control={<Radio />} label='Disable' />
        <FormControlLabel value control={<Radio />} label='Enable' />
      </RadioGroup>
    </FormControl>
  );
};

import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {Form} from 'react-final-form';
import DateFnsUtils from '@date-io/date-fns';
import {Grid, MenuItem} from '@material-ui/core';
import {DatePicker, Select} from 'mui-rff';
import Box from '@mui/material/Box';
//
import ButtonSubmit from 'components/common/ButtonSubmit';
import {getWlIdDropdownValues, getEndpointDropdownValues} from 'actions/reports';

const dateFns = new DateFnsUtils();

const getInitialFilterProps = (props) => ({
  startDate: dateFns.format(new Date(), 'yyyy-MM-dd'),
  endDate: dateFns.format(new Date(), 'yyyy-MM-dd'),
  groupBy: props.groupBy,
});

const FilterPanel = (props) => {
    const [wlidOptions, setWlidOptions] = useState([]);
    const [endpointOptions, setEndpointOptions] = useState([]);

    useEffect(() => {
      populateDropdowns();
    }, []);

    const populateDropdowns = async () => {
      const wlids = await getWlIdDropdownValues();
      const endpoints = await getEndpointDropdownValues();

      setWlidOptions(wlids);
      setEndpointOptions(endpoints);
    };

    const onSubmit = async (values) => {
      const filtersProps = {
        ...values,
        ...(values.startDate && !_.isString(values.startDate) ? {startDate: dateFns.format(values.startDate, 'yyyy-MM-dd')} : {}),
        ...(values.endDate && !_.isString(values.endDate) ? {endDate: dateFns.format(values.endDate, 'yyyy-MM-dd')} : {}),
      };

      await props.applyFilters(filtersProps);
    };

    return (
      <div>
        <Form
          initialValues={getInitialFilterProps(props)}
          onSubmit={(values) => onSubmit(values)}
          render={({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container alignItems='flex-start' spacing={5}>
                <Grid item xs={2}>
                  <DatePicker
                    name='startDate'
                    margin='none'
                    label='Start Date'
                    dateFunsUtils={DateFnsUtils}
                    format='yyyy-MM-dd'
                  />
                </Grid>
                <Grid item xs={2}>
                  <DatePicker
                    defaultValue={dateFns.format(new Date(), 'yyyy-MM-dd')}
                    name='endDate'
                    margin='none'
                    label='End Date'
                    dateFunsUtils={DateFnsUtils}
                    format='yyyy-MM-dd'
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name='wlid'
                    label='Client'
                    multiple
                  >
                    {
                    wlidOptions.map((item, index) => (
                      <MenuItem value={item.wlid} key={index}>{item.label}</MenuItem>
                    ))
                  }
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name='endpoint'
                    label='Endpoint'
                    multiple
                  >
                    {
                      endpointOptions.map((item, index) => (
                        <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                      ))
                    }
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name='adType'
                    label='Ad Type'
                    multiple
                  >
                    <MenuItem value='BANNER'>Banner</MenuItem>
                    <MenuItem value='POP'>Pop</MenuItem>
                    <MenuItem value='PUSH'>Push</MenuItem>
                    <MenuItem value='VIDEO'>Video</MenuItem>
                    <MenuItem value='NATIVE'>Native</MenuItem>
                    <MenuItem value='CALENDAR'>Calendar</MenuItem>
                    <MenuItem value='CTV'>CTV</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name='groupBy'
                    label='Group By'
                    multiple={false}
                  >
                    <MenuItem value='createdDate'>Date</MenuItem>
                    <MenuItem value='uPublisherId'>Endpoint</MenuItem>
                    <MenuItem value='wlid'>Client</MenuItem>
                    <MenuItem value='geo'>Geo</MenuItem>
                  </Select>
                </Grid>
                <Grid item style={{marginTop: 16}}>
                  <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                    <ButtonSubmit
                      type='submit'
                      name='Apply'
                      onSubmit={handleSubmit}
                      isPending={submitting}
                    />
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    );
};

export default FilterPanel;
